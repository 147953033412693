.about {
  width: 100%;
  /* height: 120vh; */
  background-image: url(./background.jpg);
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  /* background-color: rgba(255, 0, 0, 0.3); */
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* font-family: "Roboto", sans-serif; */
}
