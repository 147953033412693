#bigabout {
  background-image: url("background.jpg");
}

#register {
  background-image: url("background.jpg");
  background-size: cover;
}

@keyframes jugad {
  0% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(30px);
  }
}

@media screen and (min-width: 601px) {
  #parabout {
    font-size: 1.5rem;
    padding-bottom: 2%;
  }
  #about {
    max-width: 20%;
    margin-right: 10%;
    margin-left: auto;
    padding-bottom: 4%;
    animation: img 1s ease-out;
    animation-iteration-count: infinite;
  }
  @keyframes img {
    0% {
      transform: translateX(60px);
    }
    50% {
      transform: translateX(-60px);
    }
    100% {
      transform: translateX(60px);
    }
  }
  #aboutsec {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  #parabout {
    font-size: 0.75rem;
    margin-bottom: 5%;
  }
  #about {
    display: block;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
