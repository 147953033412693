#bigcontact {
  background-image: url("../assets/backgroung.jpg");
}
.memberbox {
  margin: 20px 50px;
  height: 100px;
  width: 250px;
  box-shadow: 0px 2px 7px -4px #000;
  transition: 0.1s linear;
  backface-visibility: hidden;
  border-radius: 3px;
  border: none;
  overflow: hidden;
}

.memberbox:hover {
  box-shadow: 0px 2px 7px -1px #000;
  transition: 0.1s linear;
  border: none;
  backface-visibility: hidden;
}

.memberbox:hover .mphotohover {
  opacity: 0.9;
  transition: 0.1s linear;
  backface-visibility: hidden;
}

.membername {
  background-image: url("imgbg.jpg");
  padding: 18px;
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.membersubtitle {
  background-image: url("imgbg.jpg");
  padding: 12px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.about {
  width: 100%;
  /* height: 120vh; */
  background-image: url(./jugaadaboutbg.jpg);
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  /* background-color: rgba(255, 0, 0, 0.3); */
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* font-family: "Roboto", sans-serif; */
}
