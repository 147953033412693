html {
  scroll-behavior: smooth;
  color-scheme: dark;
}
.herobg {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/backgroung.jpg);
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* font-family: "Roboto", sans-serif; */
}
